import React, { useRef, useEffect,useState, useMemo, MouseEvent } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

class Info extends React.Component {
  render() {
  return (
    <div className='m-auto overflow-auto text-white'>
      <div className='text-3xl text-center'>決算公告</div>
      <div className='my-auto'>
        <div className='object-scale-down h-96 overflow-clip '>
            <p className='text-white'>第2期決算公告</p>
            <Document file="2022年度貸借対照表.pdf">
            <Page pageNumber={1} />
            </Document>
        </div>
        <div className='h-4'></div>
        <div className='object-scale-down h-96 overflow-clip'>
            <p className='text-white'>第1期決算公告</p>
            <Document file="2021年度貸借対照表.pdf">
            <Page pageNumber={1} />
            </Document>
        </div>
      </div>
      </div>
    );
  }
}

export default Info;